<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Empresas Cadastradas</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="obterInformacoes">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-chip
                      v-for="(value, key) in filtros"
                      v-if="(value != null && value.length > 0)"
                      :key="key"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value }}
                  </v-chip>
                  <v-btn class="ml-3 float-right" color="orange" to="/empresas/cadastrar" dark small>
                    <v-icon>mdi-plus</v-icon>
                    Nova Empresa
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="permissaoPagina">
                <v-col cols="12">
                  <v-data-table
                      :headers="headersTabela"
                      :items="dadosTabela"
                      locale="pt"
                      class="elevation-0"
                  >
                    <template v-slot:item.habilitado="{ item }">
									<span :class="obterClasseHabilitado(item)">
										{{ obterDescricaoHabilitado(item) }}
									</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom content-class="pa-0 px-2" v-for="(btnAcao, key) in btnsAcoes" :key="key">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="btnAcao.acao(item)">
                            <v-icon
                                v-text="btnAcao.icone"
                                :color="btnAcao.cor"
                                v-bind="attrs"
                                v-on="on"
                                medium
                            />
                          </v-btn>
                        </template>

                        <span class="font-size-13">{{ btnAcao.tooltip }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />

      <dialog-confirmacao ref="dialogConfirmacao" v-on:confirmou="excluirEmpresa()"/>

      <dialog-detalhes ref="dialogDetalhes"/>

    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import DialogConfirmacao from "../../components/DialogConfirmacao";
import DialogDetalhes from "../../components/empresas/DialogDetalhes";
import mixinGeral from "../../mixin/geral";
import empresasServices from "../../services/empresas";
import DialogFiltro from "@/components/empresas/DialogFiltro"
import {mapGetters, mapActions} from "vuex";

export default {
  name: "Index",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    DialogConfirmacao,
    DialogDetalhes,
    DialogFiltro
  },
  data() {
    return {
      modalFiltro: false,
      idEmpresaExclusao: null,
      filtros: {
        razao: null,
        cnpj: null,
      },
    };
  },
  computed: {
    permissaoPagina() {
      return this.permissaoMaster;
    },
    headersTabela() {
      return [
        {
          text: "Razão Social",
          value: "razaoSocial",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "CNPJ",
          value: "cnpj",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Habilitada",
          value: "habilitado",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Opções",
          value: "actions",
          sortable: false,
          class: "",
          cellClass: "sem-quebra",
        },
      ];
    },
    dadosTabela() {
      return this.registrosFiltrados;
    },
    btnsAcoes() {
      return [
        {
          tooltip: "Ver detalhes",
          cor: "grey darken-1",
          icone: "mdi-arrow-expand-all",
          acao: this.abrirDetalhes,
        },
        {
          tooltip: "Editar",
          cor: "blue darken-1",
          icone: "mdi-pencil",
          acao: this.irParaEdicao,
        },
        {
          tooltip: "Excluir",
          cor: "red darken-1",
          icone: "mdi-delete",
          acao: this.abrirConfirmacaoExclusao,
        },
      ];
    },
    registrosFiltrados() {
      let filtrados = [...this.empresas];

      const filtrandoRazao = !!this.filtros.razao;
      const filtrandoCnpj = !!this.filtros.cnpj;

      if (filtrandoRazao) filtrados = this.filtrarRazaoSocial(filtrados);
      if (filtrandoCnpj) filtrados = this.filtrarCnpj(filtrados);

      return filtrados;
    },
  },
  methods: {
    ...mapActions({obterInformacoes: "actionObterInformacoes"}),
    obterDescricaoHabilitado(item) {
      return !!item.habilitado ? "SIM" : "NÃO";
    },
    obterClasseHabilitado(item) {
      return !!item.habilitado ? "green--text font-weight-bold" : "red--text font-weight-bold";
    },
    irParaEdicao(item) {
      const id = item.id;
      return this.navegar("empresasEditar", {id});
    },
    abrirConfirmacaoExclusao(item) {
      this.idEmpresaExclusao = item.id;
      this.$refs.dialogConfirmacao.modelo = "excluirEmpresa";
      this.$refs.dialogConfirmacao.exibir();
    },
    async excluirEmpresa() {
      this.ativarLoading();
      try {
        await empresasServices.excluir(this.idEmpresaExclusao);
        await this.obterInformacoes();
        this.notificacaoSucesso("Empresa e Usuarios desativados");
      } catch (e) {
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
    filtrarRazaoSocial(filtrados) {
      return filtrados.filter((f) => {
        return f.razaoSocial.toUpperCase().indexOf(this.filtros.razao.toUpperCase()) !== -1;
      });
    },
    filtrarCnpj(filtrados) {
      return filtrados.filter((f) => {
        return f.cnpj.toUpperCase().indexOf(this.filtros.cnpj.toUpperCase()) !== -1;
      });
    },
    abrirDetalhes(item) {
      this.$refs.dialogDetalhes.dados = item;
      this.$refs.dialogDetalhes.exibir();
    },
    filtrar(obj) {
      this.filtros = obj;
    },
  },
  watch: {},
  created() {
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--cor-primaria-100) !important;
}
</style>