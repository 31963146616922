<template>
  <v-dialog v-model="aberto" width="90%" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Detalhes da Empresa</div>
          <v-btn icon dark @click="fechar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5" v-if="!!dados">
        <div class="mt-4 mb-3">
          <span class="font-weight-bold font-size-16">Dados da Empresa</span>
        </div>
        <v-row class="px-5">
          <v-col cols="12">
            <v-text-field
                :value="dados.razaoSocial"
                label="Razão Social"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.cnpj"
                label="CNPJ"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.habilitado ? 'Habilitada' : 'Desabilitada'"
                label="Habilitação da Empresa"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.cep || '-'"
                label="CEP"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.cidade || '-'"
                label="Cidade"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.logradouro || '-'"
                label="Logradouro"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.logradouro_numero || '-'"
                label="Logradouro Numero"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.bairro || '-'"
                label="Bairro"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.uf || '-'"
                label="UF"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.ddd || '-'"
                label="DDD"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.telefone || '-'"
                label="Telefone"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dataCadastro || '-'"
                label="Data do Cadastro"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dataAtualizacao || '-'"
                label="Última Atualização"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>
        </v-row>

        <div class="mt-8 mb-3">
          <span class="font-weight-bold font-size-16">Dados Bancarios</span>
        </div>
        <v-row class="px-5">
          <v-col cols="12" md="6">
            <AutocompleteBancos
                :id="dados.banco"
                v-on:bancoSelecionado="bancoRetornado"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AutocompleteTipoConta
                :id="dados.tipo_conta"
                v-on:tipoContaSelecionado="tipoContaRetornado"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.agencia"
                label="Agencia"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.conta"
                label="Conta"
                outlined
                hide-details
                readonly
                dense
            />
          </v-col>
        </v-row>


        <div class="mt-8 mb-3">
          <span class="font-weight-bold font-size-16">Documentos</span>
        </div>
        <v-row class="px-5" justify="center">
          <v-col cols="12" md="4" align="center">
            <v-btn
                :disabled="!dados.doc_contrato_social"
                outlined
                color="orange darken-4"
                width="90%"
                @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = dados.doc_contrato_social"
            >Contrato Social
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <v-btn
                :disabled="!dados.doc_creci"
                outlined
                color="orange darken-4"
                width="90%"
                @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = dados.doc_creci"
            >Creci
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <v-btn
                :disabled="!dados.doc_responsavel"
                outlined
                color="orange darken-4"
                width="90%"
                @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = dados.doc_responsavel"
            >Doc. do Responsavel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="orange"
            @click="fechar">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  <dialog-show-arquivo
      ref="DialogShowArquivo"
      :titulo="'Comprovante de Pagamento'"
      :aberto="dialogShowArquivo.status"
      :url_arquivo="dialogShowArquivo.arquivo"
      @fechar="dialogShowArquivo.status = false"
  ></dialog-show-arquivo>
  </v-dialog>


</template>

<script>
import moment from "moment";
import mixinGeral from "../../mixin/geral";
import AutocompleteBancos from "@/components/autocomplete/Bancos";
import AutocompleteTipoConta from "@/components/autocomplete/TipoContaBanco";
import DialogShowArquivo from "@/components/DialogShowArquivo";

export default {
  name: "DialogDetalhes",
  mixins: [mixinGeral],
  components: {AutocompleteBancos, AutocompleteTipoConta,DialogShowArquivo},
  data() {
    return {
      aberto: false,
      dados: null,
      dialogShowArquivo: {
        status: false,
        arquivo: null,
      },
    };
  },
  computed: {
    backgroundInput() {
      return "grey lighten-3";
    },
    dataCadastro() {
      if (!this.dados) return "";
      const data = this.dados.criadoEm;
      return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    },
    dataAtualizacao() {
      if (!this.dados) return "";
      const data = this.dados.atualizadoEm;
      return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    },
  },
  methods: {
    exibir() {
      this.aberto = true;
    },
    fechar() {
      this.aberto = false;
      this.dados = null;
    },
    bancoRetornado(value) {
      this.dados.banco = value
    },
    tipoContaRetornado(value) {
      this.dados.tipo_conta = value
    },
  }
}
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}

</style>