<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Filtrar Empresas</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row else>
          <v-col cols="12">
            <v-text-field
                label="Buscar por razão social"
                placeholder="Razão social da empresa"
                outlined
                dense
                v-model.trim="form.razao"
                hide-details
                clearable
                prepend-inner-icon="mdi-domain"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
                label="Buscar por CNPJ"
                placeholder="CNPJ da empresa"
                outlined
                dense
                v-model.trim="form.cnpj"
                hide-details
                clearable
                prepend-inner-icon="mdi-card-text-outline"
                v-mask="'##.###.###/####-##'"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            :disabled="loading_dados"
            color="orange darken-3"
            text
            @click="fechar">Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";

export default {
  name: "DialogoFiltro",
  mixins: [mixinGeral],
  props: {
    aberto: Boolean
  },
  data() {
    return {
      options_empresas: [],
      loading_dados: false,
      form: {
        razao: null,
        cnpj: null
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  watch: {
    aberto: function (val) {
      if (val) {
        this.loading_dados = false
      } else {
        this.loading_dados = true
      }
    }
  },
  methods: {
    empresaRetornada(value) {
      this.form.empresa = value
    },
    ufRetornado(value) {
      this.form.uf = value
    },
    aprovacaoRetornado(value) {
      this.form.aprovacaoSerasa = value
    },
    fechar() {
      this.$emit('fechar', false);
    }
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>